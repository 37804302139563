import React, {useEffect, useState, SyntheticEvent} from 'react';
import {NavLink, useHistory} from 'react-router-dom';
import {Form, Button} from 'react-bootstrap';
import configData from '../../config';
import axios from 'axios';
import {toast} from 'react-toastify';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const TokenExportForm = (props) => {
    const [Name, setName] = useState(props?.location?.aboutProps?.name);
    const [Type, setType] = useState('');
    const [Group, setGroup] = useState('');
    const [Schedule, setSchedule] = useState('');
    const [Enabled, setEnabled] = useState(true);
    const account = JSON.parse(window.localStorage.getItem('userLoggin'));
    const [id, setId] = useState(props?.location?.aboutProps?.id);
    const [loadingData, setLoadingData] = useState(false);
    const history = useHistory();
    const [AudioFileOption, setAudioFileOption] = useState('18c');
    const [AudioFileOptions, setAudioFileOptions] = useState([]);
    const [selectEnabled, setSelectEnabled] = useState(true);
    const [fileEnabled, setFileEnabled] = useState(false);
    const [File, setFile] = useState(null);
    // const FileDownload = require('js-file-download');

    const apiTokenizationURL  = "https://gecko-token-api.natasquad.com";
    const tokenizedFilesURL = "https://audio-tokenized.natasquad.com";

    const exportData = async (e) => {
        e.preventDefault();
        setLoadingData(true);
        try {
            if (value == 'tokenize') {
                return tokenizeFile();
            }
            var file = "";
            if (value == 'qr')
                file = Name + '.svg';

            var optionsFile = "";
            if (value == "us") {
                optionsFile = AudioFileOption;
                let suffix = "";
                switch (optionsFile) {
                    case "17c":
                        suffix = "_17_175_continuous";
                        break;
                    case "17s":
                        suffix = "_17_175_pulsed";
                        break;
                    case "18c":
                        suffix = "_18_195_continuous";
                        break;
                    case "18s5":
                        suffix = "_18_195_pulsed_5s";
                        break;
                    case "18s10":
                        suffix = "_18_195_pulsed_10s";
                        break;
                }
                file = Name + suffix + '.wav';
            }

            axios.get(configData.API_SERVER + 'token/' + id + '/export?f=' + value + '&e=' + optionsFile,
                {
                    headers: {"Authorization": `Bearer ${account.token}`},
                    responseType: 'blob',
                })
                // 1. Convert the data into 'blob'
                .then((response) => response.data)
                .then((blob) => {

                    // 2. Create blob link to download
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', file);  // 3. Append to html page
                    document.body.appendChild(link);  // 4. Force download
                    link.click();  // 5. Clean up and remove the link
                    link.parentNode.removeChild(link);
                    this.setState({
                        loading: false
                    });
                })
                .catch(function (error) {
                    //Swal.fire({
                    //    title: 'Erreur!',
                    //    text: "Une erreur s'est produite",
                    //    icon: 'error',
                    //    confirmButtonText: 'OK'
                    //});
                    setLoadingData(false);
                });
        } catch (err) {
            toast.error('An error occurred!', {
                position: toast.POSITION.TOP_RIGHT
            });
            setLoadingData(false);
        }
    }

    const tokenizeFile = async () => {
        let originalFilename = File.name.split('.').slice(0, -1).join('.')
        var originalExt = File.name.substring(File.name.lastIndexOf('.') + 1);

        let formData = new FormData();
        formData.append('file', File);

        let response = await fetch(`${apiTokenizationURL}/upload`, {
            method: 'POST',
            body: formData
        });
        let uploadedFile = await response.json();
        let uploadedfileNameNew = uploadedFile['fileNameNew'];
        let uploadedFileType = uploadedFile['fileType'];

        let wmId = id;
        if (configData.ENVIRONMENT_NAME === 'DEMO')
            wmId += 50000;

        let idStr = String(wmId).padStart(5, '0');
        response = await fetch(`${apiTokenizationURL}/tokenization/${idStr}/${uploadedfileNameNew}/${uploadedFileType}`, {
            method: 'GET'
        });
        let wmJson = await response.json();
        console.log("MW", wmJson);
        let tokenizedFileUrl = `${tokenizedFilesURL}/${wmJson['fileNameWithWathermarking']}`;

        let wmBlob = await (await fetch(tokenizedFileUrl)).blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([wmBlob]));
        link.setAttribute('download', `${originalFilename}_${Name}.${originalExt}`);  // 3. Append to html page
        document.body.appendChild(link);  // 4. Force download
        link.click();  // 5. Clean up and remove the link
        link.parentNode.removeChild(link);
        // this.setState({
        //     loading: false
        // });
        setLoadingData(false);
    }

    useEffect(() => {
        // getById();
        var selector = document.getElementById('title-section-id');
        if (selector != undefined) {
            selector.textContent = "Export Token";
        }

        var audioOptions = [
            // {
            //     id: '17c',
            //     name: '17-17.5 kHz, continuous'
            // },
            // {
            //     id: '17s',
            //     name: '17-17.5 kHz, pulsed'
            // },
            {
                id: '18c',
                name: '18-19.5 kHz, continuous'
            },
            {
                id: '18s5',
                name: '18-19.5 kHz, pulsed 5 seconds',
            },
            {
                id: '18s10',
                name: '18-19.5 kHz, pulsed 10 seconds'
            },
        ];
        setAudioFileOptions(audioOptions);

    }, []);

    const [value, setValue] = React.useState('us');

    const handleChange = (event) => {
        setValue(event.target.value);

        if(event.target.value != "us"){
            setSelectEnabled(false);
            // setAudioFileOption('18c');
            setFileEnabled(true)
        }

        else {
            setSelectEnabled(true);
            setFileEnabled(false)
        }
    };

    const selectFile = async (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setFile(file);
        }
    }

    return (
        <>
            <div className={loadingData ? "preload" : "d-none"}>
                <div className="preloader-spinner"></div>
            </div>
            <Form className="create-form" onSubmit={exportData} autoComplete="off">
                <div className="card">
                    <div className="card-body">
                        <div className="row">

                                <div className="col-md-3">
                                    <FormControl>
                                        <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={value}
                                            onChange={handleChange}
                                        >
                                            <FormControlLabel value="us" control={<Radio/>}
                                                              label="Download UST audio file"/>


                                            {/*<FormControlLabel value="qr" control={<Radio/>} label="Download QR code"/>*/}
                                            <FormControlLabel value="tokenize" control={<Radio/>}
                                                              label="Insert watermarking token"/>
                                            <input type="file" name="image" id="tokenizeSource"
                                                   disabled={!fileEnabled}
                                                  accept="audio/*,video/*" onChange={selectFile}/>
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            <div className="col-md-9">
                            <select className="form-control audio_file_options" disabled={!selectEnabled} name="AudioFileOption" value={AudioFileOption} onChange={(e) => setAudioFileOption(e.target.value)}>
                                            {AudioFileOptions.map((item, i) => (
                                                <option key={i} value={item.id}>{item.name}</option>
                                            ))}
                                        </select>
                                    </div>



                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col-12 footer-form">
                                <NavLink className="btn btn-secondary" to="/manage/tokens"><i
                                    className="fa fa-fw fa-times"></i> Cancel</NavLink>
                                <Button className="btn btn-primary" type='submit'><i
                                    className="fa fa-fw fa-floppy-o"></i> Export</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default TokenExportForm;
