import React, {useEffect, useState} from 'react';

import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";

import {Grid} from 'gridjs-react';
import "gridjs/dist/theme/mermaid.css";

import DateRangePicker from './../components/DateRangePicker';
import axios from "axios";
import configData from "../config";
import ApexCharts from "apexcharts";
import $ from 'jquery';
import moment from 'moment';
import Select from "react-select";
import makeAnimated from "react-select/animated";

function Analytics() {

    const [TotalActiveTokens, setTotalActiveTokens] = useState(0);
    const [TotalActiveCampaigns, setTotalActiveCampaigns] = useState(0);
    const [TotalImpressions, setTotalImpressions] = useState(0);
    const [TotalEngagements, setTotalEngagements] = useState(0);
    const [Tokens, setTokens] = useState([]);
    const [Token, setToken] = useState();
    const [Contents, setContents] = useState([]);
    const [Content, setContent] = useState();
    const [loadingData, setLoadingData] = useState(false);
    const account = JSON.parse(window.localStorage.getItem('userLoggin'));
    const [PerformanceTable, setPerformanceTable] = useState([]);
    const animatedComponent = makeAnimated();

    const getDataAnalytics = async () => {
        setLoadingData(true);
        const arrayTokens = [];
        const arrayContents = [];

        if (Token) {
            Token.map((option) => (
                arrayTokens.push(option.value)
            ))
        }

        if (Content) {
            Content.map((option) => (
                arrayContents.push(option.value)
            ))
        }
        try {
            axios.post(configData.API_SERVER + 'analytics',
                {
                    start_date: moment($('#reportrange').data('daterangepicker').startDate).format('YYYY-MM-DD'),
                    end_date: moment($('#reportrange').data('daterangepicker').endDate).format('YYYY-MM-DD'),
                    tokens: arrayTokens,
                    contents: arrayContents
                },
                {
                    headers: {"Authorization": `Bearer ${account.token}`}
                })
                .then(function (response) {
                    if (response.status == 200) {
                        var result = response.data;

                        document.getElementById("chartEngagements").innerHTML = "";
                        const chartEngagements = new ApexCharts(document.getElementById("chartEngagements"), result.engagements_chart);
                        chartEngagements.render();

                        document.getElementById("chartImpressions").innerHTML = "";
                        const chartImpressions = new ApexCharts(document.getElementById("chartImpressions"), result.impressions_chart);
                        chartImpressions.render();

                        setTotalActiveTokens(result.active_tokens);
                        setTotalActiveCampaigns(result.active_campaigns);
                        setTotalImpressions(result.impressions);
                        setTotalEngagements(result.engagements);

                        console.log(result.performance_table);
                        setPerformanceTable(result.performance_table.map(x => [
                            x['content__name'],
                            x['impression_count'],
                            x['engagement_count']
                        ]));
                    }
                    setLoadingData(false);
                })
                .catch(function (error) {
                    setLoadingData(false);
                });
        } catch (err) {
            setLoadingData(false);
        }
    }

    const getTokens = async () => {
        setLoadingData(true);
        try {
            axios.get(configData.API_SERVER + 'tokens?limit=1000',
                {
                    headers: {"Authorization": `Bearer ${account.token}`}
                })
                .then(function (response) {
                    if (response.status == 200) {
                        var result = response.data.results;
                        var data = [];
                        result.map((option) => (
                            data.push({
                                value: option.id,
                                label: option.name
                            })
                        ));
                        setTokens(data);
                        setLoadingData(false);

                    }
                    if (Tokens.length)
                        setLoadingData(false);
                })
                .catch(function (error) {
                    setLoadingData(false);
                });
        } catch (err) {
            setLoadingData(false);
        }
    }

    const getContents = async () => {
        setLoadingData(true);
        try {
            axios.get(configData.API_SERVER + 'contents?limit=1000',
                {
                    headers: {"Authorization": `Bearer ${account.token}`}
                })
                .then(function (response) {
                    if (response.status == 200) {
                        var result = response.data.results;
                        var data = [];
                        result.map((option) => (
                            data.push({
                                value: option.id,
                                label: option.name + ((option.ref && option.ref != "") ? " [" + option.ref + "]" : "")
                            })
                        ));
                        setContents(data);
                        setLoadingData(false);

                    }
                    if (Contents.length)
                        setLoadingData(false);
                })
                .catch(function (error) {
                    setLoadingData(false);
                });
        } catch (err) {
            setLoadingData(false);
        }
    }

    const resertForm = () => {
        setContent([]);
        setToken([]);
        getDataAnalytics();
    }
    const searchForm = () => {
        getDataAnalytics();
    }

    useEffect(() => {
        getDataAnalytics();
        getTokens();
        getContents();
    }, []);


    return (
        <>
            <div className={loadingData ? "preload" : "d-none"}>
                <div className="preloader-spinner"></div>
            </div>
            <link rel="stylesheet" type="text/css"
                  href="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.css"/>
            <Container fluid>
                <Row>
                    <Col>
                        <Card className="card-stats">
                            <Card.Header>
                                <Card.Title as="h4">Filters</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col md="4" className="mb-3">
                                        <Form.Group className="form-group">
                                            <Form.Label>Date range</Form.Label>
                                            <DateRangePicker/>
                                        </Form.Group>
                                    </Col>
                                    <Col md="4" className="mb-3">
                                        <Form.Group className="form-group">
                                            <Form.Label>Channel</Form.Label>
                                            <Select
                                                isMulti
                                                closeMenuOnSelect={false}
                                                components={animatedComponent}
                                                onChange={(items) => setToken(items)}
                                                options={Tokens}
                                                value={Token}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md="4" className="mb-3">
                                        <Form.Group className="form-group">
                                            <Form.Label>Content</Form.Label>
                                            <Select
                                                isMulti
                                                closeMenuOnSelect={false}
                                                components={animatedComponent}
                                                onChange={(items) => setContent(items)}
                                                options={Contents}
                                                value={Content}
                                            />

                                        </Form.Group>
                                    </Col>
                                    <Col md="12" className="mb-3">
                                        <div className="form-footer">
                                            <button type="submit" className="btn btn-primary button-filters"
                                                    onClick={searchForm}><i className="fa fa-fw fa-search"></i> Filter
                                            </button>
                                            <button type="button" className="btn btn-secondary button-filters"
                                                    onClick={resertForm}><i className="fa fa-fw fa-refresh"></i> Reset
                                            </button>
                                        </div>
                                    </Col>

                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
                <Row>
                    <Col lg="3" sm="6">
                        <Card className="card-stats">
                            <Card.Header>
                                <h4 className="my-1">Total active tokens</h4>
                            </Card.Header>
                            <Card.Body>
                                <h1 className="mt-0">{TotalActiveTokens}</h1>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="3" sm="6">
                        <Card className="card-stats">
                            <Card.Header>
                                <h4 className="my-1">Total active campaigns</h4>
                            </Card.Header>
                            <Card.Body>
                                <h1 className="mt-0">{TotalActiveCampaigns}</h1>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="3" sm="6">
                        <Card className="card-stats">
                            <Card.Header>
                                <h4 className="my-1">Total impressions</h4>
                            </Card.Header>
                            <Card.Body>
                                <h1 className="mt-0">{TotalImpressions}</h1>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="3" sm="6">
                        <Card className="card-stats">
                            <Card.Header>
                                <h4 className="my-1">Total engagements</h4>
                            </Card.Header>
                            <Card.Body>
                                <h1 className="mt-0">{TotalEngagements}</h1>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <Card>
                            <Card.Header>
                                <h4 className="my-1">Impressions</h4>
                            </Card.Header>
                            <Card.Body>
                                <div id="chartImpressions"></div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="6">
                        <Card>
                            <Card.Header>
                                <h4 className="my-1">Engagements</h4>
                            </Card.Header>
                            <Card.Body>
                                <div id="chartEngagements"></div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="12">
                        <Card>
                            <Card.Header>
                                <h4 className="my-1">Campaign performance</h4>
                            </Card.Header>
                            <Card.Body>
                                <Grid
                                    columns={['Campaign', 'Impressions', 'Engagements']}
                                    search={true}
                                    sort={true}
                                    data={PerformanceTable}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">

                    </Col>
                    <Col md="6">

                    </Col>


                </Row>
            </Container>
        </>
    );
}

export default Analytics;
